import sha256 from 'crypto-js/sha256';
import { apiURL } from './api';
import { sendRegisterEvent } from './apiAnalytics';

/**
 * Crea un usuario en la base de datos y de autentica
 * @param {string} email 
 * @param {string} name
 * @param {string} password 
 * @param {string} userType
 * @returns {array} retorna un arreglo con un booleano y un mensaje
 */
export const createUser = async (email, name, password, userType) => {
    try {
        const response = await fetch(apiURL+"/users/create/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                name: name,
                password: sha256(password).toString(),
                userType: userType,
            })
        });
        if (response.ok) {
            sendRegisterEvent(email);
            return [true, null];
        }
        let data = await response.json();
        console.error("Error al crear el usuario: "+data.error);
        return [false, data.error];
    } catch (error) {
        console.error(error);
        return [false, "Error al crear el usuario"];
    }
}

/**
 * Envia un correo para recuperar la contraseña
 * @param {string} email correo del usuario
 * @returns {boolean} retorna verdadero si envio el correo
 */
export const recoverPassword = async (email) => {
    try {
        const response = await fetch(apiURL+"/users/recover/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email
            })
        });
        if (response.ok) {
            return true;
        }
        console.error("Error al enviar el correo de recuperación");
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}


/**
 * Loguea un usuario en la base de datos
 * @param {string} email correo del usuario
 * @param {string} password contraseña del usuario
 * @returns usuario logueado
 */
export const loginUser = async (email, password) => {
    try {
        const response = await fetch(apiURL+"/users/login/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: sha256(password).toString()
            })
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        console.error("Error al loguear el usuario");
        return null;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}


/**
 * Cierra la sesion del usuario
 * @returns boolean retorna verdadero si cerro la sesion
 */
export const logoutUser = async () => {
    try {
        //await auth.signOut();
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
};

/**
 * Función para obtener la información de un usuario
 * @param {string} userId id del usuario
 * @returns {object} retorna la información del usuario
 */
export const getUser = async (userId) => {
    try {
        const response = await fetch(apiURL+"/users/"+userId+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        console.error("Error al obtener la información del usuario");
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Función para actualizar la información de un usuario
 * @param {string} userId id del usuario
 * @param {string} name nombre del usuario
 * @param {string} email correo del usuario
 * @param {string} phone telefono del usuario
 * @param {string} address direccion del usuario
 * @param {string} firstName nombre del usuario
 * @param {string} lastName apellido del usuario
 * @returns {array} retorna un arreglo con un booleano y un mensaje
 */
export const updateUser = async (userId, name, email, phone, address, firstName, lastName, rut) => {
    try {
        const response = await fetch(apiURL+"/users/update/"+userId+"/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                address: address,
                firstName: firstName,
                lastName: lastName,
                rut: rut
            })
        });
        if (response.ok) {
            return [true, null];
        }
        let data = await response.json();
        return [false, data.error];
    } catch (error) {
        console.error(error);
        return [false, "Error al actualizar la información del usuario"];
    }
}

/**
 * Función para subir una foto de perfil
 * @param {string} userId id del usuario
 * @param {File} file archivo a subir (foto)
 * @returns {boolean} retorna verdadero si actualizo la foto
 */
export const updateProfilePhoto = async (userId, file) => {
    try {
        const formData = new FormData();
        formData.append("photo", file);
        formData.append("userId", userId);
        const response = await fetch(apiURL+"/users/photo/"+userId+"/", {
            method: "POST",
            body: formData
        });
        if (response.ok) {
            return true;
        } else {
            console.error("Error al subir la foto de perfil");
            return false;
        }
    } catch (error) {
        console.error(error);
        return false;
    }
}




export const getRemindersByUser = async (id) => {
    try {
        const response = await fetch(apiURL+"/users/reminders/"+id+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        console.error("Error al obtener los recordatorios");
        return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const addReminder = async (userId,reminder, petId) => {
    try {
        const response = await fetch(apiURL+"/users/reminders/create/"+petId+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                reminder: reminder,
                user: userId,
            })
        });
        if (response.ok) {
            return true;
        }
        console.error("Error al añadir el recordatorio");
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const deleteReminder = async (id) => {
    try {
        const response = await fetch(apiURL+"/users/reminders/delete/"+id+"/",{
            method: "DELETE",
        });
        if (response.ok) {
            return true;
        }
        console.error("Error al eliminar el recordatorio");
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export const updateReminder = async (id, reminder) => {
    try {
        const response = await fetch(apiURL+"/users/reminders/update/"+id+"/",{
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: reminder.name,
                date: reminder.date,
                description: reminder.description,
            })
        });
        if (response.ok) {
            return true;
        }
        console.error("Error al actualizar el recordatorio");
        return false;
    } catch (error) {
        console.error(error);
        return false;
    }
}

/**
 * Función para obtener los usuarios
 * @param {string} search substring para buscar usuarios
 * @returns {array} retorna un arreglo con los usuarios encontrados
 */
export const searchUsers = async (search) => {
    try {
        const response = await fetch(apiURL+"/users/search/"+search+"/");
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        console.error("Error al buscar usuarios");
        return [];
    } catch (error) {
        console.error(error);
        return [];
    }
}